import esApiV3 from './esapiv3.service';
import exception from './exception.service';

const api = {
  serviceCategories: () => '/service-categories/all',
  serviceCategoryGroups: () => '/service-categories/groups',
};

export async function getServiceCategories() {
  try {
    const res = await esApiV3.get(api.serviceCategories());
    return res.data;
  } catch (ex) {
    exception.showCustomError(
      ex,
      'An error occurred while trying to get service categories',
    );
  }
}

export async function getServiceCategoryGroups() {
  try {
    const res = await esApiV3.get(api.serviceCategoryGroups());
    return res.data;
  } catch (ex) {
    exception.showCustomError(
      ex,
      'An error occurred while trying to get service category groups',
    );
  }
}
