import esApiV3 from './esapiv3.service';
import exception from './exception.service';

const api = {
  areas: () => '/areas',
};

export async function getAreas() {
  try {
    const res = await esApiV3.get(api.areas());
    return res.data;
  } catch (ex) {
    exception.showCustomError(
      ex,
      'An error occurred while trying to get areas',
    );
  }
}
