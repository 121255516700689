const module = {
  namespaced: true,
};

module.state = {
  snackbarVisible: false,
  snackbarText: '',
  snackbarColor: '',
};

module.mutations = {
  SET_SNACKBAR(state, { visible, text, color }) {
    state.snackbarVisible = visible;
    state.snackbarText = text;
    state.snackbarColor = color;
  },
};

module.actions = {
  generalSuccess(context) {
    context.commit('SET_SNACKBAR', {
      visible: true,
      text: 'Save Successful',
      color: 'success',
    });
  },
  generalError(context) {
    context.commit('SET_SNACKBAR', {
      visible: true,
      text: 'An error occurred while trying to save.',
      color: 'error',
    });
  },
  customMessage(context, message) {
    context.commit('SET_SNACKBAR', {
      visible: true,
      text: message,
      color: 'info',
    });
  },
  customSuccess(context, successMsg) {
    context.commit('SET_SNACKBAR', {
      visible: true,
      text: successMsg,
      color: 'success',
    });
  },
  customError(context, errorMsg) {
    context.commit('SET_SNACKBAR', {
      visible: true,
      text: errorMsg,
      color: 'error',
    });
  },
  resetSnackbar(context) {
    context.commit('SET_SNACKBAR', {
      visible: false,
      text: '',
      color: '',
    });
  },
};

export default module;
