import { cloneDeep } from 'lodash';
import {
  addNote,
  getAttachmentsForRequest,
  getRequestDetailById,
  getTaggableUsers,
  uploadAttachment,
  getProofOfValueMetricsForRequest,
} from '@/service/request.service';
import { getCommunicationLogs } from '@/service/telephony.service';
import {
  getSelfServiceRequestById,
  getSelfServicePmRequestHistory,
  getRequestPrompts,
  getFollowUps,
  getPmChecklistItemsForRequest,
  markPmChecklistItem,
  bulkCrudPmChecklistItem,
} from '@/service/self-service.service';
import { allActivityTypes } from '@/constants/activityTypes';

const module = {
  namespaced: true,
};

const DEFAULT_FILTERS = {
  locationId: [],
  requestPriority: null,
  serviceCategoryId: null,
  assignedToVendorId: null,
  requestStatus: 'Request Open',
  requestType: null,
  warrantyEligible: null,
  orderBy: 'createdAt',
  orderDirection: 'DESC',
};

module.state = {
  priorities: Object.freeze({
    LOW: 'Low',
    MEDIUM: 'Medium',
    HIGH: 'High',
    EMERGENCY: 'Emergency',
  }),
  contactMethods: Object.freeze({
    CALL: 'Call',
    EMAIL: 'Email',
    TEXT: 'Text',
    SLACK: 'Slack',
  }),
  types: Object.freeze({
    INCIDENT: 'Incident',
    PM: 'Preventative',
  }),
  filter: cloneDeep(DEFAULT_FILTERS),
  request: null,
  proofOfValueMetrics: {},
  attachments: [],
  pagination: null,
  taskBarCurrentTab: null,
  totalNumberOfCommunicationItems: 0,
  communicationsTableData: null,
  requestPmHistory: [],
  prompts: [],
  followUps: [],
  pmChecklistItems: [],
  taggableUsers: [],
  openPreviousDispatchAccordion: null,
};

module.mutations = {
  SET_FILTER(state, filter) {
    state.filter = filter;
  },
  SET_REQUEST(state, request) {
    state.request = request;
  },
  SET_PROOF_OF_VALUE_METRICS(state, metrics) {
    state.proofOfValueMetrics = metrics;
  },
  SET_ATTACHMENTS(state, attachments) {
    state.attachments = attachments;
  },
  SET_PAGINATION(state, pagination) {
    state.pagination = pagination;
  },
  GET_PAGINATION_ITEMS(state) {
    state.pagination.getItems();
  },
  SET_CURRENT_TAB(state, tabIndex) {
    state.taskBarCurrentTab = tabIndex;
  },
  SET_COMMUNICATIONS_SUMMARIES(state, response) {
    state.totalNumberOfCommunicationItems = response.count;
    state.communicationsTableData = response.data;
  },
  SET_REQUEST_PM_HISTORY(state, requests) {
    state.requestPmHistory = requests;
  },
  SET_PROMPTS(state, prompts) {
    state.prompts = prompts;
  },
  SET_FOLLOW_UPS(state, followUps) {
    state.followUps = followUps;
  },
  SET_PM_CHECKLIST_ITEMS(state, pmChecklistItemsData) {
    state.pmChecklistItems = pmChecklistItemsData;
  },
  SET_TAGGABLE_USERS(state, taggableUsers) {
    state.taggableUsers = taggableUsers;
  },
  SET_DISPATCH_ACCORDION(state, value) {
    state.openPreviousDispatchAccordion = value;
  },
};

module.actions = {
  setFilter({ commit }, filter) {
    commit('SET_FILTER', filter);
  },
  clearFilter({ commit }) {
    commit('SET_FILTER', cloneDeep(DEFAULT_FILTERS));
  },
  setPagination({ commit }, pagination) {
    commit('SET_PAGINATION', pagination);
  },
  async getRequestDetail({ commit, dispatch }, id) {
    dispatch('getRequestAttachments', id);
    dispatch('getRequestProofOfValueMetrics', id);
    const result = await getRequestDetailById(id);
    commit('SET_REQUEST', result);
  },
  async getRequestProofOfValueMetrics({ commit }, id) {
    const savingsMetrics = await getProofOfValueMetricsForRequest(id);
    commit('SET_PROOF_OF_VALUE_METRICS', savingsMetrics ?? {});
  },
  async getRequestAttachments({ commit }, id) {
    const attachments = await getAttachmentsForRequest(id);
    commit('SET_ATTACHMENTS', attachments ?? []);
  },
  setAttachments({ commit }, attachments) {
    commit('SET_ATTACHMENTS', attachments ?? []);
  },
  async addNote({ dispatch, state }, { text, actionRequired }) {
    if (state.request.id) {
      await addNote(state.request.id, { text, actionRequired });
      dispatch('getRequestDetail', state.request.id);
    }
  },
  async uploadRequestAttachment(
    { commit, state, dispatch },
    { attachment, attachmentType, requestId, taskId },
  ) {
    if (state.request?.id || requestId) {
      const [response, code] = await uploadAttachment(
        state.request?.id || requestId,
        attachment,
        attachmentType,
        taskId,
      );

      if (code === 202) {
        dispatch(
          'snackbar/customMessage',
          `${attachmentType} already uploaded for request`,
          { root: true },
        );
      } else {
        commit('SET_ATTACHMENTS', [...(state.attachments ?? []), response]);
      }
    }
  },
  clearRequest({ commit }) {
    commit('SET_REQUEST', null);
  },
  setTaskBarTab(context, tabIndex) {
    context.commit('SET_CURRENT_TAB', tabIndex);
  },
  async getCommunicationTableData(context, query) {
    const response = await getCommunicationLogs({
      ...query,
    });
    context.commit('SET_COMMUNICATIONS_SUMMARIES', response);
  },
  async getSSORequestDetail({ commit }, id) {
    const [result, prompts, followUps, pmChecklistItemsForRequest] =
      await Promise.allSettled([
        getSelfServiceRequestById(id),
        getRequestPrompts(id),
        getFollowUps(id),
        getPmChecklistItemsForRequest(id, ['completedByUser']),
      ]);
    if (result.status === 'fulfilled') {
      const request = result.value;

      commit('SET_REQUEST', request);
      if (
        request?.requestType === 'Self Service Preventative' &&
        request?.pmThreadId?.length
      ) {
        const requests = await getSelfServicePmRequestHistory(
          request?.pmThreadId,
        );
        commit(
          'SET_REQUEST_PM_HISTORY',
          requests?.filter((r) => r.id !== request.id) ?? [],
        );
      } else {
        commit('SET_REQUEST_PM_HISTORY', []);
      }
    }
    if (prompts.status === 'fulfilled') {
      commit('SET_PROMPTS', prompts.value);
    }
    if (followUps.status === 'fulfilled') {
      commit('SET_FOLLOW_UPS', followUps.value);
    }

    if (pmChecklistItemsForRequest.status === 'fulfilled') {
      commit('SET_PM_CHECKLIST_ITEMS', pmChecklistItemsForRequest.value.data);
    }
  },
  async getPromptInfo(context, requestId) {
    const prompts = await getRequestPrompts(requestId);
    context.commit('SET_PROMPTS', prompts);
  },
  async getRequestById({ commit }, id) {
    const result = await getSelfServiceRequestById(id);
    commit('SET_REQUEST', result);
  },
  clearRequestPmHistory({ commit }) {
    commit('SET_REQUEST_PM_HISTORY', []);
  },

  async markRequestPmChecklistItem(
    { commit, state },
    { checklistItemId, status },
  ) {
    const updatedItem = await markPmChecklistItem({
      id: checklistItemId,
      status,
    });

    let updatedPmChecklistItems = [...state.pmChecklistItems];

    // update the item in the array of checklist items with the updated item
    updatedPmChecklistItems = updatedPmChecklistItems.map((item) => {
      if (item.id === updatedItem.id) {
        return updatedItem;
      }
      return item;
    });

    commit('SET_PM_CHECKLIST_ITEMS', updatedPmChecklistItems);
  },

  async bulkCrudPmChecklistItem({ commit, state }, payload) {
    await bulkCrudPmChecklistItem(payload);

    const getRefreshedItems = (
      await getPmChecklistItemsForRequest(state.request.id, ['completedByUser'])
    ).data;

    commit('SET_PM_CHECKLIST_ITEMS', getRefreshedItems);
  },

  async getTaggableUsers({ commit }, requestId) {
    const taggableUsers = await getTaggableUsers(requestId);
    commit('SET_TAGGABLE_USERS', taggableUsers);
  },
};

module.getters = {
  priorities: (state) => Object.values(state.priorities),
  types: (state) => Object.values(state.types),
  requestId: (state) => state.request?.id,
  request: (state) => state.request,
  isIncident: (state) =>
    state.request.requestType === 'Incident' ||
    state.request.requestType === 'Self Service Incident',
  isPm: (state) =>
    state.request.requestType === 'Preventative' ||
    state.request.requestType === 'Self Service Preventative',
  dispatchConfirmationActivityObjects: (state) =>
    state.request?.activities?.filter(
      (it) => it.activityType === allActivityTypes.DISPATCH_CONFIRMATION,
    ),
  dispatchConfirmationTaskObjects: (state) =>
    state.request?.tasks?.filter(
      (it) =>
        it.activityType === allActivityTypes.DISPATCH_CONFIRMATION &&
        !it.completedAt,
    ),
};

export default module;
