import {
  getTaskById,
  getMyTasksByQuery,
  getTaskShareRecipients,
} from '@/service/task.service';
import { getPmScheduleById } from '@/service/pm-schedule.service';
import dayjs from 'dayjs';

const module = {
  namespaced: true,
};

module.state = {
  externalActivityType: Object.freeze({
    INTERNAL_TECHNICIAN_RESOLUTION: 'Internal Technician Resolution',
  }),
  myTasksData: {},
  selectedTask: {},
  selectedTaskPmSchedule: {},
  selectedTaskShareRecipients: [],
  indexOfSelectedTask: null,
  myTasksSort: {
    orderBy: 'score',
    orderDirection: 'DESC',
  },
};

module.mutations = {
  SET_MY_TASKS(state, taskData) {
    state.myTasksData = taskData;
  },
  ADD_TO_MY_TASKS(state, taskData) {
    state.myTasksData.data.push(...taskData.data);
  },
  SET_SELECTED_TASK(state, task) {
    state.selectedTask = task;
  },
  SET_SELECTED_TASK_INDEX(state, index) {
    state.indexOfSelectedTask = index;
  },
  SET_SELECTED_TASK_PM_SCHEDULE(state, schedule) {
    state.selectedTaskPmSchedule = schedule;
  },
  SET_SELECTED_TASK_SHARE_RECIPIENTS(state, contacts) {
    state.selectedTaskShareRecipients = contacts;
  },
};

module.actions = {
  async getMyTasks(context, { query, isFirstPage }) {
    const taskData = await getMyTasksByQuery({
      ...query,
    });
    if (isFirstPage) {
      context.commit('SET_MY_TASKS', taskData);
    } else {
      context.commit('ADD_TO_MY_TASKS', taskData);
    }
  },
  async getTaskDetails(context, taskId) {
    const task = await getTaskById(taskId);
    context.commit('SET_SELECTED_TASK', task);

    const taskShareContacts = await getTaskShareRecipients(taskId);
    const { formattedContacts } = await context.dispatch(
      'getFormattedContactData',
      taskShareContacts,
    );
    context.commit('SET_SELECTED_TASK_SHARE_RECIPIENTS', formattedContacts);

    if (task.request.requestType === 'Preventative') {
      const pmSchedule = await getPmScheduleById(task.request.pm.pmScheduleId);
      context.commit('SET_SELECTED_TASK_PM_SCHEDULE', pmSchedule);
    }
  },
  setSelectedTaskIndex(context, index) {
    context.commit('SET_SELECTED_TASK_INDEX', index);
  },

  getFormattedContactData(context, contacts) {
    function getOOONote(oooStartDate, oooEndDate) {
      return oooStartDate &&
        dayjs().isBetween(oooStartDate, oooEndDate, 'day', '[]')
        ? '<b>OOO</b> - '
        : '';
    }
    const formattedContacts = [];

    if (contacts.locationContacts.length) {
      formattedContacts.push({ header: 'Location' }, { divider: true });
      contacts.locationContacts.forEach((it) => {
        it.group = 'location';
        it.isOoo = getOOONote(it.oooStartDate, it.oooEndDate);
      });
      formattedContacts.push(...contacts.locationContacts);
    }

    if (contacts.customerContacts.length) {
      formattedContacts.push({ header: 'Corporate' }, { divider: true });
      contacts.customerContacts.forEach((it) => {
        it.group = 'corporate';
        it.isOoo = getOOONote(it.oooStartDate, it.oooEndDate);
      });
      formattedContacts.push(...contacts.customerContacts);
    }

    if (contacts.vendorContacts.length) {
      formattedContacts.push({ header: 'Vendor' }, { divider: true });
      contacts.vendorContacts.forEach((it) => {
        it.group = 'vendor';
        it.isOoo = getOOONote(it.oooStartDate, it.oooEndDate);
      });
      formattedContacts.push(...contacts.vendorContacts);
    }

    return {
      formattedContacts,
      contacts,
    };
  },
};

module.getters = {
  externalActivityType: (state) => state.externalActivityType,
};

export default module;
