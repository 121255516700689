import axios from 'axios';
import config from '@/config';

const api = {
  magicLink: () => 'v3/ssp-request-intake-magic-link/requests/magicLink',
  assetPickerView: () =>
    'v3/ssp-request-intake-magic-link/requests/magicLink/asset/summaries',
  viewableLocations: () =>
    'v3/ssp-request-intake-magic-link/requests/magicLink/viewableLocations',
  manufacturer: () =>
    'v3/ssp-request-intake-magic-link/requests/magicLink/manufacturers',
  manufacturerById: (id) =>
    `v3/ssp-request-intake-magic-link/requests/magicLink/manufacturers/${id}`,
  equipmentModelNumbers: () =>
    'v3/ssp-request-intake-magic-link/requests/magicLink/assets/modelNumbers',
  serviceCategories: () =>
    'v3/ssp-request-intake-magic-link/requests/magicLink/service-categories/all',
  assetTypes: () =>
    'v3/ssp-request-intake-magic-link/requests/magicLink/asset-types/all',
  asset: () => 'v3/ssp-request-intake-magic-link/requests/magicLink/asset',
};

export async function getInitialIntakeData(token, recaptchaToken) {
  const res = await axios.get(
    new URL(api.magicLink(), config.esApiUrl).toString(),
    {
      headers: { Authorization: `Bearer ${token}`, token: recaptchaToken },
    },
  );
  return res.data;
}

export async function saveRequest(token, recaptchaToken, body) {
  const result = await axios.post(
    new URL(api.magicLink(), config.esApiUrl).toString(),
    body,
    {
      headers: { Authorization: `Bearer ${token}`, token: recaptchaToken },
    },
  );
  return result;
}

export async function getAssetPickerViewMagicLinkIntake(
  params,
  token,
  recaptchaToken,
) {
  const res = await axios.get(
    new URL(api.assetPickerView(), config.esApiUrl).toString(),
    {
      params,
      headers: { Authorization: `Bearer ${token}`, token: recaptchaToken },
    },
  );
  return res.data;
}

export async function getViewableLocations(params, token, recaptchaToken) {
  const res = await axios.get(
    new URL(api.viewableLocations(), config.esApiUrl).toString(),
    {
      params,
      headers: { Authorization: `Bearer ${token}`, token: recaptchaToken },
    },
  );
  return res.data;
}

export async function getManufacturers(params, token, recaptchaToken) {
  const res = await axios.get(
    new URL(api.manufacturer(), config.esApiUrl).toString(),
    {
      params,
      headers: { Authorization: `Bearer ${token}`, token: recaptchaToken },
    },
  );
  return res.data;
}

export async function getManufacturerById(params, token, recaptchaToken) {
  const res = await axios.get(
    new URL(api.manufacturerById(), config.esApiUrl).toString(),
    {
      params,
      headers: { Authorization: `Bearer ${token}`, token: recaptchaToken },
    },
  );
  return res.data;
}

export async function getAssetModelNumbers(params, token, recaptchaToken) {
  const res = await axios.get(
    new URL(api.equipmentModelNumbers(), config.esApiUrl).toString(),
    {
      params,
      headers: { Authorization: `Bearer ${token}`, token: recaptchaToken },
    },
  );
  return res.data;
}

export async function getInfrastructureByLocationId(
  locationId,
  token,
  recaptchaToken,
) {
  const res = await axios.get(
    new URL(api.assetPickerView(), config.esApiUrl).toString(),
    {
      params: {
        isInfrastructure: true,
        locationId,
        limit: 100,
      },
      headers: { Authorization: `Bearer ${token}`, token: recaptchaToken },
    },
  );
  return res.data;
}

export async function getServiceCategories(token, recaptchaToken) {
  const res = await axios.get(
    new URL(api.serviceCategories(), config.esApiUrl).toString(),
    {
      headers: { Authorization: `Bearer ${token}`, token: recaptchaToken },
    },
  );
  return res.data;
}

export async function getAssetTypes(token, recaptchaToken) {
  const res = await axios.get(
    new URL(api.assetTypes(), config.esApiUrl).toString(),
    {
      headers: { Authorization: `Bearer ${token}`, token: recaptchaToken },
    },
  );
  return res.data;
}

export async function createAsset(token, recaptchaToken, body) {
  const asset = await axios.post(
    new URL(api.asset(), config.esApiUrl).toString(),
    body,
    {
      headers: { Authorization: `Bearer ${token}`, token: recaptchaToken },
    },
  );
  return asset;
}
