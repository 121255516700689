import { getAreas } from '@/service/areas.service';

const module = {
  namespaced: true,
};

module.state = {
  areas: [],
};

module.mutations = {
  SET_AREAS(state, areas) {
    state.areas = areas;
  },
};

module.actions = {
  async getAreas({ commit, state }) {
    if (state.areas.length === 0) {
      const results = await getAreas();
      commit('SET_AREAS', results);
    }
  },
};

module.getters = {
  areas: (state) => state.areas,
};

export default module;
