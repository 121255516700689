import axios from 'axios';
import config from '@/config';
import exception from '@/service/exception.service';

const api = {
  task: () => 'v3/ssp-vendor-communication/tasks',
  taskById: (id) => `v3/ssp-vendor-communication/tasks/${id}`,
  request: () => 'v3/ssp-vendor-communication/requests',

  uploadAttachment: () => 'v3/ssp-vendor-communication/requests/attachments',
  vendorDispatch: () => 'v3/ssp-vendor-communication/requests/dispatch',
  completeVendorDispatch: () =>
    'v3/ssp-vendor-communication/requests/dispatch/complete',
  vendorFollowUp: () => 'v3/ssp-vendor-communication/requests/vendor-follow-up',
  completeVendorFollowUp: () =>
    'v3/ssp-vendor-communication/requests/vendor-follow-up/complete',

  vendorRequestQuote: () => 'v3/ssp-vendor-communication/requests/quote',
  completeVendorRequestQuote: () =>
    'v3/ssp-vendor-communication/requests/quote/complete',
};

export async function uploadAttachment(
  token,
  recaptchaToken,
  id,
  attachment,
  attachmentType,
  taskId,
) {
  const formData = new FormData();
  formData.append('file', attachment);
  formData.append('attachmentType', attachmentType);
  formData.append('requestId', id);
  if (taskId != null) formData.append('taskId', taskId);
  try {
    const res = await axios.post(
      new URL(api.uploadAttachment(), config.esApiUrl).toString(),
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
          token: recaptchaToken,
        },
      },
    );
    return [res.data, res.status];
  } catch (ex) {
    exception.showCustomError(
      ex,
      'An error occurred while trying to upload attachment',
    );
  }
}

export async function getRequest(token, recaptchaToken) {
  const res = await axios.get(
    new URL(api.request(), config.esApiUrl).toString(),
    {
      headers: { Authorization: `Bearer ${token}`, token: recaptchaToken },
    },
  );
  return res.data;
}

export async function getTask(token, recaptchaToken) {
  const res = await axios.get(new URL(api.task(), config.esApiUrl).toString(), {
    headers: { Authorization: `Bearer ${token}`, token: recaptchaToken },
  });
  return res.data;
}

export async function getTaskById(token, recaptchaToken, id) {
  const res = await axios.get(
    new URL(api.taskById(id), config.esApiUrl).toString(),
    {
      headers: { Authorization: `Bearer ${token}`, token: recaptchaToken },
    },
  );
  return res.data;
}

export async function vendorDispatch(token, recaptchaToken) {
  return axios.get(new URL(api.vendorDispatch(), config.esApiUrl).toString(), {
    headers: { Authorization: `Bearer ${token}`, token: recaptchaToken },
  });
}

export async function completeVendorDispatch(token, recaptchaToken, body) {
  return axios.post(
    new URL(api.completeVendorDispatch(), config.esApiUrl).toString(),
    body,
    {
      headers: { Authorization: `Bearer ${token}`, token: recaptchaToken },
    },
  );
}

export async function vendorQuoteRequest(token, recaptchaToken) {
  return axios.get(
    new URL(api.vendorRequestQuote(), config.esApiUrl).toString(),
    {
      headers: { Authorization: `Bearer ${token}`, token: recaptchaToken },
    },
  );
}

export async function completeVendorRequestQuote(
  token,
  recaptchaToken,
  body,
  quoteFile,
) {
  const formData = new FormData();
  formData.append('file', quoteFile);

  Object.entries(body).forEach(([key, value]) => {
    if (value != null) {
      formData.append(key, value);
    }
  });

  return axios.post(
    new URL(api.completeVendorRequestQuote(), config.esApiUrl).toString(),
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`,
        token: recaptchaToken,
      },
    },
  );
}

export async function vendorFollowUp(token, recaptchaToken) {
  return axios.get(new URL(api.vendorFollowUp(), config.esApiUrl).toString(), {
    headers: { Authorization: `Bearer ${token}`, token: recaptchaToken },
  });
}

export async function completeVendorFollowUp(token, recaptchaToken, body) {
  return axios.post(
    new URL(api.completeVendorFollowUp(), config.esApiUrl).toString(),
    body,
    {
      headers: { Authorization: `Bearer ${token}`, token: recaptchaToken },
    },
  );
}
