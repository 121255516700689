import ovenIcon from '@/assets/86_Repairs-Icon-Oven.png';
import boltIcon from '@/assets/86_Repairs-Icon-Screw_Bolt.png';
import primaryLogo from '@/assets/86repairs-primary-logo-horizontal.png';
import whiteLogo from '@/assets/86repairs-white-horizontal.png';
import manufacturersIcon from '@/assets/Icon_Manufacturers.png';
import timeIcon from '@/assets/Icon_Time.png';
import troubleshootingIcon from '@/assets/Icon_Troubleshooting.png';
import appMockupImage from '@/assets/app_mockup.png';
import appStoreBadge from '@/assets/app-store-badge.png';
import googlePlayBadge from '@/assets/google-play-badge.png';

const module = {
  namespaced: true,
};

module.state = {
  ovenIcon,
  boltIcon,
  primaryLogo,
  whiteLogo,
  manufacturersIcon,
  timeIcon,
  troubleshootingIcon,
  appMockupImage,
  appStoreBadge,
  googlePlayBadge,
};

export default module;
