import axios from 'axios';
import Cookies from 'js-cookie';
import jwtDecode from 'jwt-decode';
import config from '@/config';
import exception from '@/service/exception.service';
import esApiV3 from './esapiv3.service';
import userProfileService from './user-profile.service';

const esAuth = axios.create({
  baseURL: new URL('v3/', config.esApiUrl).toString(),
  withCredentials: true,
});

const api = {
  getCaslRules: () => 'permissions/rules/self',
  getPermissionConfigs: () => 'permissions/',
  rolesAndRanks: () => 'permissions/roles',
  verifyLegacyMagicLink: () => '/auth/legacymagiclink/verify',
  verifyMagicLink: () => '/auth/magiclink/verify',
  requestMagicLink: () => '/auth/magiclink/request',
  requestOtpEmail: () => '/auth/otp/request/email',
  requestOtpPhone: () => '/auth/otp/request/phone',
  verifyOtp: () => '/auth/otp/verify',
  revokeAccessToken: () => '/auth/revoke/ssp',
  unlinkLoginProvider: (provider) => `/auth/${provider}/unlink`,
};

const legacyAuthTokenStorageItemName = 'eighty_token';

const selectedCustomerIdKey = 'selectedCustomerId';

export default {
  getCookieDomain() {
    return config.mode === 'local' ? '' : '.86repairs.com';
  },
  clearLegacyAuthToken() {
    localStorage.removeItem(legacyAuthTokenStorageItemName);
  },
  clearAuthToken() {
    userProfileService.clearImpersonatedUserId(false);
    this.clearSelectedCustomerId(false);
    return Cookies.set(config.esApiToken, '', {
      domain: this.getCookieDomain(),
    });
  },
  getAuthToken() {
    return Cookies.get(config.esApiToken);
  },
  saveAuthTokenToCookie(token) {
    return Cookies.set(config.esApiToken, token, {
      domain: this.getCookieDomain(),
    });
  },
  getAuthTokenPayload(token = null) {
    return jwtDecode(token ?? this.getAuthToken());
  },
  hasAuthToken() {
    return !!this.getAuthToken();
  },
  getLegacyAuthToken() {
    return localStorage.getItem(legacyAuthTokenStorageItemName);
  },
  hasLegacyAuthToken() {
    return !!this.getLegacyAuthToken();
  },
  setSelectedCustomerId(customerId) {
    Cookies.set(selectedCustomerIdKey, customerId, {
      domain: this.getCookieDomain(),
    });
    window.location.assign('/');
  },
  clearSelectedCustomerId(reload = true) {
    Cookies.set(selectedCustomerIdKey, '', {
      domain: this.getCookieDomain(),
    });
    if (reload) {
      window.location.assign('/');
    }
  },
  getRequestMagicLinkUrl() {
    const route = '/auth/magiclink/request';
    return new URL(route, config.esApiUrl).toString();
  },
  async verifyLegacyMagicToken(token) {
    try {
      await esAuth.get(api.verifyLegacyMagicLink(), {
        params: {
          token,
        },
      });
    } catch (error) {
      console.log('Error verifying magic link', error);
      return false;
    }
    userProfileService.handleSuccessfulLogin();
    return true;
  },
  async verifySingleUseMagicToken(token) {
    try {
      await esAuth.get(api.verifyMagicLink(), {
        params: {
          token,
        },
      });
    } catch (error) {
      console.log('Error verifying magic link', error);
      return false;
    }
    userProfileService.handleSuccessfulLogin();
    return true;
  },
  async requestLoginCode(recaptchaToken, emailOrPhone) {
    // we already know this is a valid email or phone
    const isEmail = emailOrPhone.includes('@');
    let formattedValue = emailOrPhone;
    if (
      !isEmail &&
      emailOrPhone.length === 11 &&
      !emailOrPhone.includes('+1')
    ) {
      formattedValue = emailOrPhone.replace('+', '+1');
    }
    const endpoint = isEmail ? api.requestOtpEmail() : api.requestOtpPhone();
    const field = isEmail ? 'email' : 'phone';
    try {
      const verification = await esAuth.post(
        endpoint,
        {
          [field]: formattedValue,
        },
        {
          headers: { token: recaptchaToken },
        },
      );
      if (!verification.data.sid) {
        return { error: 'Could not find an account with this email or phone.' };
      }
      return verification.data;
    } catch (error) {
      console.log(
        'Failed to send request for login code',
        error.response.data.message || error,
      );
      if (error?.response?.data?.message) {
        return { error: error.response.data.message };
      }
    }
  },
  async checkLoginCode(user, sid, code) {
    try {
      await esAuth.get(api.verifyOtp(), {
        params: {
          user,
          sid,
          code,
        },
      });
    } catch (error) {
      console.log('Failed to verify login code', error);
      return false;
    }
    userProfileService.handleSuccessfulLogin();
    return true;
  },
  unlinkLoginProvider(provider) {
    return esAuth.post(api.unlinkLoginProvider(provider)).catch((ex) => {
      exception.showCustomError(
        ex,
        'An error occurred while trying to unlink login provider',
      );
    });
  },
  async getUserCaslRules() {
    try {
      const { data } = await esApiV3.get(api.getCaslRules());
      return data;
    } catch (ex) {
      console.warn('Error getting user casl rules', ex);
      return null;
    }
  },
  async getPermissionConfigs() {
    try {
      const { data } = await esApiV3.get(api.getPermissionConfigs());
      return data;
    } catch (ex) {
      console.warn('Error getting permission configs', ex);
      return null;
    }
  },
  async revokeAccessToken() {
    try {
      await esAuth.post(api.revokeAccessToken());
    } catch (error) {
      console.log('Error revoking token', error);
    }
  },
  async getRolesAndRanks() {
    try {
      const { data } = await esApiV3.get(api.rolesAndRanks());
      return data;
    } catch (ex) {
      console.warn('Error getting roles and ranks', ex);
      return null;
    }
  },
};
