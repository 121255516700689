import analyticsService from '@/service/analytics.service';
import userProfileService from '@/service/user-profile.service';

const module = {
  namespaced: true,
};

const newRequestObject = {
  location: null,
  contact: null,
  priority: 'Low',
  asset: null,
  vendor: null,
  incidentDescription: null,
  symptoms: [],
};

module.state = {
  showRequestDialog: false,
  showEquipmentDialog: false,
  newRequest: { ...newRequestObject },
  contactsForLocation: [],
  descriptionPlaceholder:
    "Example: Our only walk-in freezer is temping in the 20s. There's power and no breakers tripped.",
};

module.mutations = {
  SET_SHOW_REQUEST_DIALOG(state, showDialog) {
    if (showDialog) {
      analyticsService.logEvent('New Request Click');
    }
    state.showRequestDialog = showDialog;
  },
  SET_LOCATIONS(state, locations) {
    state.locations = locations;
  },
  RESET_NEW_REQUEST(state) {
    state.newRequest = { ...newRequestObject };
  },
  SET_NEW_REQUEST_EQUIPMENT(state, asset) {
    state.newRequest.asset = asset;
  },
  SET_CONTACTS_FOR_LOCATION(state, contacts) {
    state.contactsForLocation = contacts;
  },
};

module.actions = {
  async toggleRequestDialog({ state, commit }) {
    commit('RESET_NEW_REQUEST');
    return commit('SET_SHOW_REQUEST_DIALOG', !state.showRequestDialog);
  },
  async resetNewRequest({ commit }) {
    return commit('RESET_NEW_REQUEST');
  },
  async setEquipment({ commit }, asset) {
    commit('SET_NEW_REQUEST_EQUIPMENT', asset);
  },
  async getContactsForLocation({ commit }, locationId) {
    const contacts = await userProfileService.getLocationContacts(locationId);
    contacts.locationContacts.forEach((it) => {
      it.group = 'location';
      it.label = `${it.name} (Location)`;
    });
    contacts.customerContacts.forEach((it) => {
      it.group = 'corporate';
      it.label = `${it.name} (Corporate)`;
    });
    contacts.otherLocationContacts.forEach((it) => {
      it.group = 'otherLocations';
      it.label = `${it.name} (Other Location)`;
    });

    const formattedContacts = [
      { header: 'Location' },
      ...contacts.locationContacts,
      { header: 'Corporate' },
      ...contacts.customerContacts,
      { header: 'Other Locations' },
      ...contacts.otherLocationContacts,
    ];
    return commit('SET_CONTACTS_FOR_LOCATION', formattedContacts);
  },
};

module.getters = {
  validRequest: (state) =>
    state.newRequest.location &&
    state.newRequest.asset &&
    (state.newRequest.incidentDescription ||
      state.newRequest.symptoms.length > 0),
};

export default module;
