import {
  getServiceCategories,
  getServiceCategoryGroups,
} from '@/service/service-category.service';

const module = {
  namespaced: true,
};

module.state = {
  serviceCategories: [],
  loading: false,
  serviceCategoryGroups: [],
  loadingGroups: false,
};

module.mutations = {
  SET_SERVICE_CATEGORIES(state, serviceCategories) {
    state.serviceCategories = serviceCategories?.sort((a, b) =>
      a.name.localeCompare(b.name),
    );
  },
  SET_LOADING(state, loading) {
    state.loading = loading;
  },
  SET_SERVICE_CATEGORY_GROUPS(state, serviceCategoryGroups) {
    state.serviceCategoryGroups = serviceCategoryGroups;
  },
  SET_LOADING_GROUPS(state, loading) {
    state.loadingGroups = loading;
  },
};

module.actions = {
  async getServiceCategories({ commit, state }) {
    if (state.serviceCategories.length === 0 && !state.loading) {
      commit('SET_LOADING', true);
      const results = await getServiceCategories();
      commit('SET_SERVICE_CATEGORIES', results);
      commit('SET_LOADING', false);
    }
  },
  async getServiceCategoryGroups({ commit, state }) {
    if (state.serviceCategoryGroups.length === 0 && !state.loadingGroups) {
      commit('SET_LOADING_GROUPS', true);
      const results = await getServiceCategoryGroups();
      commit('SET_SERVICE_CATEGORY_GROUPS', results);
      commit('SET_LOADING_GROUPS', false);
    }
  },
};

module.getters = {
  serviceCategories: (state) => state.serviceCategories,
  serviceCategoryGroups: (state) => state.serviceCategoryGroups,
  formattedServiceCategoryGroups: (state) =>
    state.serviceCategoryGroups
      .reduce((acc, group) => {
        const { categories } = group;
        const allIds = categories.map((category) => category.id);

        if (allIds && allIds.length > 0)
          acc.push({
            text: group.name,
            value: allIds,
          });

        categories
          .filter((category) => category.name !== group.name)
          .forEach((category) => {
            const text = category.name.includes(':')
              ? category.name.split(':')[1].trim()
              : category.name;
            acc.push({
              text: `${group.name}: ${text}`,
              value: [category.id],
            });
          });

        return acc;
      }, [])
      .sort((a, b) => a.text.localeCompare(b.text)),
  getServiceCategoryName: (state) => (id) =>
    state.serviceCategories.find((c) => c.id === id)?.name,
};

export default module;
