import config from '@/config';

export function getSignedUrl(originalUrl, axios) {
  if (typeof originalUrl !== 'string') return originalUrl;
  return axios
    .get(`s3?follow=false&url=${originalUrl}`)
    .then((res) => res.data);
}

const s3Plugin = {
  install(Vue, options) {
    // eslint-disable-next-line no-param-reassign
    Vue.prototype.$s3 = {
      /**
       * Takes an unsigned AWS S3 URL and returns an eightysix-api url that will 302 to the S3 URL
       * or return given url if it does not match an S3 URL.
       * @param {string} originalUrl
       * @returns {string}
       */
      signUrl(originalUrl) {
        if (typeof originalUrl !== 'string') return originalUrl;
        const match = originalUrl.match(
          /^https:\/\/(s3.([^.]*).amazonaws.com\/([^/]*)|([^.]*).s3.([^.]*).amazonaws.com)\/.{1,}$/g,
        );
        if (match) {
          return `${config.esApiUrl}/v3/ssp/s3?follow=true&url=${originalUrl}`;
        }
        return originalUrl;
      },
      /**
       * Takes an unsigned AWS S3 URL and returns a signed S3 URL
       * @param {string} originalUrl
       * @returns {Promise<string>}
       */
      getSignedUrl(originalUrl) {
        return getSignedUrl(originalUrl, options.axios);
      },
    };
  },
};

export default s3Plugin;
