<template>
  <v-app>
    <v-fade-transition>
      <v-overlay v-if="appLoading" color="white" opacity="1" light absolute>
        <div class="d-flex align-center justify-center flex-column">
          <v-img
            alt="86 Repairs Logo"
            class="shrink mb-2"
            contain
            :src="primaryLogo"
            width="150" />
          <v-progress-circular
            color="black"
            indeterminate
            size="50"></v-progress-circular>
        </div>
      </v-overlay>
    </v-fade-transition>
    <router-view></router-view>
  </v-app>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'App',
  computed: {
    ...mapState(['appLoading']),
    ...mapState('staticImages', ['primaryLogo']),
  },
};
</script>

<style lang="scss">
.v-card__text,
.v-card__title {
  word-break: normal;
}

.clickable {
  cursor: pointer;
}

.task-form-section {
  padding: 20px;
  border-top: thin solid rgba(0, 0, 0, 0.12);
}

.jwt-task {
  .task-form-section {
    background: #fff;
    border-radius: 5px;
    margin-top: 20px;
    box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),
      0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
  }
}
</style>
