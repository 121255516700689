import { getCustomerForUser, updateCustomer } from '@/service/customer.service';
import userProfileService from '@/service/user-profile.service';

const module = {
  namespaced: true,
};

module.state = {
  customer: null,
  selectedCustomer: null,
  customerExpiration: 0,
  tags: [],
  status: Object.freeze({
    LIVE: 'Live',
    PROSPECT: 'Prospect',
    ONBOARDING: 'Onboarding',
    CHURNED: 'Churned',
  }),
  activePeople: [],
  archivedPeople: [],
};

module.mutations = {
  SET_CUSTOMER(state, customer) {
    state.customer = customer;
  },
  SET_SELECTED_CUSTOMER(state, customerId) {
    state.selectedCustomer = customerId;
  },
  SET_TAGS(state, tags) {
    state.tags = tags;
  },
  SET_CUSTOMER_EXPIRATION(state, expiration) {
    state.customerExpiration = expiration;
  },
  SET_CUSTOMER_PEOPLE(state, { activePeople, archivedPeople }) {
    state.activePeople = activePeople;
    state.archivedPeople = archivedPeople;
  },
};

module.actions = {
  async updateCustomer({ commit, state }, { customerId, patch }) {
    const results = await updateCustomer(customerId, patch);
    commit('SET_CUSTOMER', results);
    if (state.selectedCustomer.id === results.id) {
      commit('SET_SELECTED_CUSTOMER', results);
    }
  },
  async setCustomerToView(context, customer) {
    context.commit('SET_SELECTED_CUSTOMER', customer);
  },
  async getCustomer({ commit, rootGetters, state }) {
    const customerId = rootGetters['auth/currentUserCustomerId'];
    if (customerId == null) {
      console.log('customerId not found on user');
      return;
    }
    const msFromEpoch = Date.now();
    if (msFromEpoch > state.customerExpiration) {
      const results = await getCustomerForUser();
      commit('SET_CUSTOMER', results);
      commit('SET_SELECTED_CUSTOMER', results);
      commit(
        'SET_TAGS',
        (results.locationGroups || []).sort((a, b) =>
          a.name.localeCompare(b.name),
        ),
      );
      commit('SET_CUSTOMER_EXPIRATION', msFromEpoch + 1000 * 60); // 1 minute
    } else {
      console.log(
        `Customer data is still fresh. Expires in ${
          (state.customerExpiration - msFromEpoch) / 1000
        } seconds`,
      );
    }
  },
  async getCustomerPeople(context) {
    const results =
      (await userProfileService.getPeople({
        withDeleted: true,
      })) || [];
    context.commit('SET_CUSTOMER_PEOPLE', {
      activePeople: results.filter((r) => !r.deletedAt),
      archivedPeople: results.filter((r) => r.deletedAt),
    });
  },
};

module.getters = {
  customer: (state) => state.customer,
  selectedCustomer: (state) => state.selectedCustomer,
  accountManagerEmail: (state) => state.customer?.accountManager?.email,
  tags: (state) => state.tags || [],
  isSelfServiceCustomer: (state) => state.selectedCustomer?.isSelfService,
};

export default module;
