export const getAllPaginatedItems = async (
  endpoint,
  query = {},
  limit = 100,
  page = 0,
  allItems = [],
) => {
  const response = await endpoint({
    ...query,
    limit,
    offset: limit * page,
  });
  const { count, data } = response;
  const newAllItems = [...allItems, ...data];

  if (newAllItems?.length >= count) {
    return newAllItems;
  }
  return getAllPaginatedItems(endpoint, query, limit, page + 1, newAllItems);
};
