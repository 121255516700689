import { getLocationsForUser } from '@/service/location.service';

const module = {
  namespaced: true,
};

module.state = {
  locations: [],
  locationsExpiration: 0,
};

module.mutations = {
  SET_LOCATIONS(state, locations) {
    state.locations = locations;
  },
  SET_LOCATIONS_EXPIRATION(state, expiration) {
    state.locationsExpiration = expiration;
  },
};

module.actions = {
  async getLocations({ commit, state }) {
    const msFromEpoch = Date.now();
    if (msFromEpoch > state.locationsExpiration) {
      const locations = await getLocationsForUser(false);
      commit('SET_LOCATIONS', locations);
      commit('SET_LOCATIONS_EXPIRATION', msFromEpoch + 1000 * 60); // 1 minute
    } else {
      console.log(
        `Location data is still fresh. Expires in ${
          (state.locationsExpiration - msFromEpoch) / 1000
        } seconds`,
      );
    }
  },
};

module.getters = {
  locations: (state) => state.locations || [],
  hasLocationWithPmEnabled: (state) =>
    !!state.locations.filter((l) => l.pmEnabled)?.length,
};

export default module;
