import { getSelfServiceVendors } from '@/service/self-service.service';

const module = {
  namespaced: true,
};

module.state = {
  vendors: [],
  vendorsExpiration: 0,
};

module.mutations = {
  SET_VENDORS(state, vendors) {
    state.vendors = vendors;
  },
  SET_VENDORS_EXPIRATION(state, expiration) {
    state.vendorsExpiration = expiration;
  },
};

module.actions = {
  async getVendors({ commit, state }) {
    const msFromEpoch = Date.now();
    if (msFromEpoch > state.vendorsExpiration) {
      const vendors = await getSelfServiceVendors();
      commit('SET_VENDORS', vendors);
      commit('SET_VENDORS_EXPIRATION', msFromEpoch + 1000 * 60); // 1 minute
    } else {
      console.log(
        `Vendor data is still fresh. Expires in ${
          (state.vendorsExpiration - msFromEpoch) / 1000
        } seconds`,
      );
    }
  },
};

module.getters = {
  vendors: (state) => state.vendors || [],
};

export default module;
