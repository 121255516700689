import store from '@/store/index';

export default {
  show: (ex) => {
    store.dispatch('snackbar/generalError');
    console.dir(ex);
    throw ex;
  },
  showCustomError: (ex, errorMsg) => {
    store.dispatch(
      'snackbar/customError',
      ex?.response?.data?.message || errorMsg,
    );
    console.dir(ex);
    throw ex;
  },
  showError: (errorMsg) => {
    store.dispatch('snackbar/customError', errorMsg);
  },
};
