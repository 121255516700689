import { requestInvoiceSummaries } from '@/service/request.service';
import { cloneDeep } from 'lodash';

const module = {
  namespaced: true,
};

const DEFAULT_FILTERS = {
  requestStartDate: null,
  requestEndDate: null,
  invoiceStartDate: null,
  invoiceEndDate: null,
  requestStatus: null,
  hasWorkOrderNumber: null,
  assignedToVendorId: null,
  locationId: [],
  assetTypeId: null,
  flagType: null,
  hasInvoice: null,
};

const transcriptionFlagOptions = [
  'Could not view',
  'Customer location not specified',
  'Location not in system',
  'Not an invoice',
  'Vendor not in system',
  'Did not manage invoice',
  'Duplicate of already matched invoice',
  'Escalate to CSM',
  'Incorrect total',
  'Preventative maintenance invoice',
  'User flag: Multiple service locations',
  'User flag: Split page of invoice',
  'Other',
  'No description of services',
  'Multiple trip charges',
];

module.state = {
  invoiceTableData: null,
  totalNumberOfTableItems: 0,
  filter: cloneDeep(DEFAULT_FILTERS),
  invoiceStats: {
    totalInvoices: 0,
    collectionRate: 0,
    totalSpend: 0,
  },
  transcriptionFlagOptions,
  flagsToShow: [
    { text: 'No description of services', icon: 'mdi-file-document-outline' },
    { text: 'Multiple trip charges', icon: 'mdi-truck' },
    {
      text: 'Duplicate of already matched invoice',
      icon: 'mdi-file-replace-outline',
    },
    {
      text: 'Overtime Rates Applied',
      icon: 'mdi-cash-multiple',
    },
    {
      text: 'Warranties May Apply',
      icon: 'mdi-shield-check',
    },
    {
      text: 'Preventative Maintenance Rate Discrepancy',
      icon: 'mdi-clipboard-clock-outline',
    },
    {
      text: 'Exceeded Asset Spending Limit',
      icon: 'mdi-fridge-industrial-alert',
    },
    {
      text: "Exceeded Dispatch Approver's Spending Limit",
      icon: 'mdi-account-cash',
    },
    {
      text: 'Contracted Labor Rate Discrepancy',
      icon: 'mdi-handshake-outline',
    },
    {
      text: 'Exceeds Quoted Amount',
      icon: 'mdi-file-sign',
    },
    {
      text: 'Invoice Hours Discrepancy',
      icon: 'mdi-timetable',
    },
    {
      text: 'Invoice Amount Exception',
      icon: 'mdi-currency-usd-off',
    },
    {
      text: 'Expected PM Visit Cost Exceeded',
      icon: 'mdi-invoice-text-clock-outline',
    },
  ],
};

module.mutations = {
  SET_REQUEST_INVOICES_SUMMARIES(state, response) {
    state.totalNumberOfTableItems = response.count;
    state.invoiceTableData = response.data;
    state.invoiceStats = {
      totalInvoices: response.invoiceCount,
      collectionRate: (
        response.requestWithInvoiceCount / response.requestCount
      ).toFixed(2),
      totalSpend: response.totalSpend,
    };
  },
  RESET_FILTERS(state) {
    state.filter = cloneDeep(DEFAULT_FILTERS);
  },
};

module.actions = {
  async getInvoicesTableData(context, query) {
    const response = await requestInvoiceSummaries(query);
    context.commit('SET_REQUEST_INVOICES_SUMMARIES', response);
  },
  resetFilters(context) {
    context.commit('RESET_FILTERS');
  },
};

export default module;
