const module = {
  namespaced: true,
};

module.state = {
  communicationMethods: Object.freeze(['Call', 'SMS', 'Email']),

  deleteDialogVendor: null,
  deleteDialogLocation: null,

  editDialogVendor: null,

  newDialogOpen: false,

  addDialogVendor: null,
  addDialogLocation: null,

  vendorDialogOpen: false,
  vendor: null,

  vendorAssociationDialogOpen: false,
  vendorAssociationLocations: [],
  vendorAssociationDialogVendor: null,

  filter: {},
  loading: false,
};

module.actions = {
  openDeleteDialogVendor({ commit }, { vendor, location }) {
    commit('SET_DELETE_DIALOG_VENDOR', vendor);
    commit('SET_DELETE_DIALOG_LOCATION', location);
  },
  closeDeleteDialog({ commit }) {
    commit('SET_DELETE_DIALOG_VENDOR', null);
    commit('SET_DELETE_DIALOG_LOCATION', null);
  },
  openEditDialogVendor({ commit }, vendor) {
    commit('SET_EDIT_DIALOG_VENDOR', vendor);
  },
  closeEditDialog({ commit }) {
    commit('SET_EDIT_DIALOG_VENDOR', null);
  },
  openAddDialog({ commit }, { vendor, location }) {
    commit('SET_ADD_DIALOG_VENDOR', vendor);
    commit('SET_ADD_DIALOG_LOCATION', location);
  },
  closeAddDialog({ commit }) {
    commit('SET_ADD_DIALOG_VENDOR', null);
    commit('SET_ADD_DIALOG_LOCATION', null);
  },
  openNewDialog({ commit }) {
    commit('SET_NEW_DIALOG_OPEN', true);
  },
  closeNewDialog({ commit }) {
    commit('SET_NEW_DIALOG_OPEN', false);
  },
  setFilter({ commit }, filter) {
    commit('SET_FILTER', filter);
  },
  setLoading({ commit }, loading) {
    commit('SET_LOADING', loading);
  },
  openVendorDialog({ commit }, vendor) {
    commit('SET_VENDOR', vendor);
    commit('SET_VENDOR_DIALOG_OPEN', true);
  },
  closeVendorDialog({ commit }) {
    commit('SET_VENDOR', null);
    commit('SET_VENDOR_DIALOG_OPEN', false);
  },
  openVendorAssociationDialog({ commit }, { locations, vendor }) {
    commit('SET_VENDOR_ASSOCIATION_LOCATIONS', locations);
    commit('SET_VENDOR_ASSOCIATION_VENDOR', vendor);
    commit('SET_VENDOR_ASSOCIATION_DIALOG_OPEN', true);
  },
  closeVendorAssociationDialog({ commit }) {
    commit('SET_VENDOR_ASSOCIATION_LOCATIONS', []);
    commit('SET_VENDOR_ASSOCIATION_VENDOR', null);
    commit('SET_VENDOR_ASSOCIATION_DIALOG_OPEN', false);
  },
  setVendorAssociationLocations({ commit }, locations) {
    commit('SET_VENDOR_ASSOCIATION_LOCATIONS', locations);
  },
};

module.mutations = {
  SET_DELETE_DIALOG_VENDOR(state, vendor) {
    state.deleteDialogVendor = vendor;
  },
  SET_DELETE_DIALOG_LOCATION(state, location) {
    state.deleteDialogLocation = location;
  },
  SET_EDIT_DIALOG_VENDOR(state, vendor) {
    state.editDialogVendor = vendor;
  },
  SET_NEW_DIALOG_OPEN(state, open) {
    state.newDialogOpen = open;
  },
  SET_ADD_DIALOG_VENDOR(state, vendor) {
    state.addDialogVendor = vendor;
  },
  SET_ADD_DIALOG_LOCATION(state, location) {
    state.addDialogLocation = location;
  },
  SET_FILTER(state, filter) {
    state.filter = filter;
  },
  SET_LOADING(state, loading) {
    state.loading = loading;
  },
  SET_VENDOR(state, vendor) {
    state.vendor = vendor;
  },
  SET_VENDOR_DIALOG_OPEN(state, open) {
    state.vendorDialogOpen = open;
  },
  SET_VENDOR_ASSOCIATION_LOCATIONS(state, locations) {
    state.vendorAssociationLocations = locations;
  },
  SET_VENDOR_ASSOCIATION_DIALOG_OPEN(state, open) {
    state.vendorAssociationDialogOpen = open;
  },
  SET_VENDOR_ASSOCIATION_VENDOR(state, vendor) {
    state.vendorAssociationDialogVendor = vendor;
  },
};

module.getters = {
  communicationMethods: (state) => state.communicationMethods,
  deleteDialogOpen: (state) =>
    state.deleteDialogVendor !== null && state.deleteDialogLocation !== null,
  editDialogOpen: (state) => state.editDialogVendor !== null,
  addDialogOpen: (state) =>
    state.addDialogVendor !== null && state.addDialogLocation !== null,
};

export default module;
