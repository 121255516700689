import axios from 'axios';
import router from '@/router';
import config from '@/config';
import authService from '@/service/auth.service';

const ALLOW_AUTH_SKIP = ['/request-intake-magic-link'];

const esApiV3 = axios.create({
  baseURL: new URL('v3/ssp/', config.esApiUrl).toString(),
  withCredentials: true,
});

esApiV3.interceptors.request.use(
  (request) => {
    request.headers['current-page'] = window.location.pathname;
    return request;
  },
  (error) => Promise.reject(error),
);

let attemptingRefresh = false;
esApiV3.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (
      !attemptingRefresh &&
      error?.response?.status === 401 &&
      !error?.config?.url?.includes('auth/token')
    ) {
      if (ALLOW_AUTH_SKIP.includes(error.config.headers['current-page']))
        return;

      attemptingRefresh = true;
      try {
        await axios.post(
          'auth/token',
          {
            app: 'ssp',
            grantType: 'refresh_token',
          },
          {
            baseURL: new URL('v3/', config.esApiUrl).toString(),
            withCredentials: true,
          },
        );
        window.location.reload();
        return;
      } catch (e) {
        console.log('Refresh error', e);
      }
      attemptingRefresh = false;
      await authService.revokeAccessToken();
      authService.clearAuthToken();
      router.push({ name: 'Login' });
    }
    return Promise.reject(error);
  },
);

export default esApiV3;
