import { cloneDeep } from 'lodash';
import {
  getAssetDetailById,
  getAssetPickerView,
  getAssetTypes,
} from '@/service/asset.service';
import { Pagination } from '@/service/pagination.service';

const DEFAULT_FILTERS = {
  percentOfSpendStart: null,
  percentOfSpendEnd: null,
  serviceCategoryIds: null,
  serviceCategoryId: null,
  locations: [],
  warrantyEligible: null,
  filterText: null,
};

const module = {
  namespaced: true,
};

module.state = {
  filter: cloneDeep(DEFAULT_FILTERS),
  pagination: null,
  asset: {},
  types: [],
  typesLoading: false,
};

module.mutations = {
  SET_FILTER(state, filter) {
    if (state.pagination) state.pagination.setQuery(filter);
    state.filter = filter;
  },
  SET_PAGINATION(state, pagination) {
    state.pagination = pagination;
  },
  GET_PAGINATION_ITEMS(state) {
    state.pagination.getItems();
  },
  SET_ASSET_DETAIL(state, asset) {
    state.asset = asset;
  },
  SET_ASSET_TYPES(state, types) {
    state.types = types;
  },
  SET_TYPES_LOADING(state, isLoading) {
    state.typesLoading = isLoading;
  },
};

module.actions = {
  setFilter({ commit }, filter) {
    commit('SET_FILTER', filter);
  },
  clearFilter({ commit }) {
    commit('SET_FILTER', cloneDeep(DEFAULT_FILTERS));
  },
  initializePagination({ commit }, filter) {
    commit(
      'SET_PAGINATION',
      new Pagination(filter || {}, 50, getAssetPickerView),
    );
  },
  getPaginationItems({ commit }) {
    commit('GET_PAGINATION_ITEMS');
  },
  setLocationFilter({ commit, dispatch }, locations) {
    commit('SET_FILTER', { locations });
    dispatch('initializePagination');
  },
  async getAssetDetail({ commit }, id) {
    const asset = await getAssetDetailById(id);
    commit('SET_ASSET_DETAIL', asset);
  },
  async getAssetTypes({ commit, state }) {
    if (state.types.length === 0 && !state.typesLoading) {
      commit('SET_TYPES_LOADING', true);
      const results = await getAssetTypes();
      commit('SET_ASSET_TYPES', results);
      commit('SET_TYPES_LOADING', false);
    }
  },
  clearAsset({ commit }) {
    commit('SET_ASSET_DETAIL', {});
  },
};

module.getters = {
  items: (state) => state.pagination?.items || [],
  totalItems: (state) => state.pagination?.count || 0,
  loading: (state) => state.pagination?.loading || false,
  types: (state) => state.types,
};

export default module;
