const module = {
  namespaced: true,
};

module.state = {
  selectedLocations: [],
  statsCalcMessage: 'Based on actual 86 Repairs cost data',
};

module.mutations = {
  SET_LOCATION_FILTER(state, value) {
    state.selectedLocations = value;
  },
};

module.actions = {
  setLocationFilter(context, value) {
    context.commit('SET_LOCATION_FILTER', value);
  },
};

export default module;
