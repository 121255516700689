import esApiV3 from './esapiv3.service';
import exception from './exception.service';

const api = {
  requestQuotes: () => 'request-quotes',
  requestQuoteById: (id) => `request-quotes/${id}`,
};

export async function getRequestQuotesByQuery(query) {
  try {
    const res = await esApiV3.get(api.requestQuotes(), { params: query });
    return res.data;
  } catch (ex) {
    exception.showCustomError(
      ex,
      'An error occurred while trying to get request quotes',
    );
  }
}

export async function getRequestQuoteById(id, query) {
  try {
    const res = await esApiV3.get(api.requestQuoteById(id), {
      params: query,
    });
    return res.data;
  } catch (ex) {
    exception.showCustomError(
      ex,
      'An error occurred while trying to get request quote',
    );
  }
}
