import { getRequestQuotesByQuery } from '@/service/request-quotes.service';
import { getAllPaginatedItems } from '@/utils/getAllPaginatedItems';

const module = {
  namespaced: true,
};

module.state = {
  quotes: null,
};

module.mutations = {
  SET_QUOTES_FOR_REQUEST(state, quotes) {
    state.quotes = quotes;
  },
};

module.actions = {
  async getQuotesForRequest(context, { requestId, orderBy }) {
    const allRequestQuotes = await getAllPaginatedItems(
      getRequestQuotesByQuery,
      {
        requestId,
        relations: [
          'vendor',
          'requestQuoteApprovals',
          'requestQuoteApprovals.approvingUser',
        ],
        orderBy,
      },
    );
    const formattedQuotes = allRequestQuotes.map((quote) => {
      const sortedApprovals = quote.requestQuoteApprovals.sort((a, b) =>
        a.createdAt > b.createdAt ? -1 : 1,
      );
      return {
        ...quote,
        latestApprovalStatus: sortedApprovals[0]?.approvalType,
      };
    });
    context.commit('SET_QUOTES_FOR_REQUEST', formattedQuotes);
    return formattedQuotes;
  },
};

export default module;
