import Vue from 'vue';
import Vuex from 'vuex';
import { cloneDeep } from 'lodash';

import AuthModule from '@/store/auth.module';
import AtAGlanceModule from '@/store/at-a-glance.module';
import RequestModule from '@/store/request.module';
import SnackbarModule from '@/store/snackbar.module';
import ServiceCategoryModule from '@/store/service-category.module';
import AssetModule from '@/store/asset.module';
import RequestIntakeModule from '@/store/request-intake.module';
import VendorModule from '@/store/vendor.module';
import AreasModule from '@/store/areas.module';
import TaskModule from '@/store/task.module';
import CustomerModule from '@/store/customer.module';
import LocationModule from '@/store/location.module';
import RequestSSPVendorModule from '@/store/ssp-vendor/request.ssp-vendor.module';
import StaticImagesModule from '@/store/static-images.module';
import TaskSSPVendorModule from '@/store/ssp-vendor/task.ssp-vendor.module';
import RequestIntakeMagicLinkModule from '@/store/token-pages/request-intake-magic-link/request-intake.magic-link-request-intake.module';
import AuthRequestIntakeMagicLinkModule from '@/store/ssp-vendor/auth.ssp-vendor.module';
import InvoiceModule from '@/store/invoices.modules';
import RequestQuotesModule from '@/store/request-quote.module';
import SelfServiceVendorModule from '@/store/self-service-vendor.module';

Vue.use(Vuex);

export const initialStoreModules = {
  auth: AuthModule,
  atAGlance: AtAGlanceModule,
  request: RequestModule,
  requestIntake: RequestIntakeModule,
  snackbar: SnackbarModule,
  serviceCategory: ServiceCategoryModule,
  asset: AssetModule,
  vendor: VendorModule,
  areas: AreasModule,
  task: TaskModule,
  customer: CustomerModule,
  location: LocationModule,
  requestQuotes: RequestQuotesModule,
  requestSSPVendor: RequestSSPVendorModule,
  staticImages: StaticImagesModule,
  taskSSPVendor: TaskSSPVendorModule,
  requestIntakeMagicLink: RequestIntakeMagicLinkModule,
  authSSP: AuthRequestIntakeMagicLinkModule,
  invoices: InvoiceModule,
  selfServiceVendor: SelfServiceVendorModule,
};

export default new Vuex.Store({
  state: {
    loginProviders: [
      {
        name: 'Google',
        icon: 'mdi-google',
        color: '#DB4437',
      },
      {
        name: 'Microsoft',
        icon: 'mdi-microsoft',
        color: '#00A4EF',
      },
      {
        name: 'Apple',
        icon: 'mdi-apple',
        color: '#000000',
      },
      {
        name: 'LinkedIn',
        icon: 'mdi-linkedin',
        color: '#0073b1',
      },
      {
        name: 'Facebook',
        icon: 'mdi-facebook',
        color: '#1877F2',
      },
    ],
    appLoading: false,
    scrollToRequest: {
      currentTicketNumber: null,
      previousTicketNumber: null,
    },
  },
  mutations: {
    resetState(state) {
      Object.entries(initialStoreModules).forEach(([key, value]) => {
        state[key] = cloneDeep(value.state);
      });
    },
    SET_APP_LOADING(state, value) {
      state.appLoading = value;
    },
    SET_SCROLL_TO_TICKET_NUMBER(
      state,
      { currentTicketNumber, previousTicketNumber },
    ) {
      state.scrollToRequest.currentTicketNumber = currentTicketNumber;
      state.scrollToRequest.previousTicketNumber = previousTicketNumber;
    },
    CLEAR_SCROLL_TO_TICKET_NUMBER(state) {
      state.scrollToRequest.currentTicketNumber = null;
      state.scrollToRequest.previousTicketNumber = null;
    },
  },
  actions: {
    copyToClipboard(context, thingToCopy) {
      navigator.clipboard.writeText(thingToCopy);
      context.dispatch(
        'snackbar/customMessage',
        `"${thingToCopy}" copied to clipboard.`,
        { root: true },
      );
    },
    setAppLoading(context, isLoading) {
      context.commit('SET_APP_LOADING', isLoading);
    },
    setScrollToTicketNumber(
      context,
      { currentTicketNumber, previousTicketNumber },
    ) {
      context.commit('SET_SCROLL_TO_TICKET_NUMBER', {
        currentTicketNumber,
        previousTicketNumber,
      });
    },
    clearScrollToTicketNumber(context) {
      context.commit('CLEAR_SCROLL_TO_TICKET_NUMBER');
    },
  },
  modules: cloneDeep(initialStoreModules),
});
